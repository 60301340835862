import { SetStateAction, useState } from "react";
import { toast } from "react-toastify";

import service from "../services/auth";
import usersService from "../services/users";
import LoadingIndicator from "../components/LoadingIndicator";

const DeleteAccountPage = () => {
  const [email, setEmail] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e: {
    target: { value: SetStateAction<string> };
  }) => setEmail(e.target.value);

  const handleAuthCodeChange = (e: {
    target: { value: SetStateAction<string> };
  }) => setAuthCode(e.target.value);

  const handleDeleteAccount = async (e: { preventDefault: VoidFunction }) => {
    e.preventDefault();

    setLoading(true);
    const { ok } = await service.loginWithCode(email, authCode);
    if (ok) {
      const deleteRes = await usersService.deleteUserAccount(email);
      setLoading(false);

      deleteRes.ok ? toast("Account deleted") : toast("Something failed");
    } else {
      toast("Invalid auth code");
      setLoading(false);
    }
  };

  const handleRequestAuthCode = async () => {
    setLoading(true);
    toast.loading("Requesting your auth code...");
    const res = await service.getAuthCode(email);
    toast.dismiss();
    setLoading(false);

    res.ok
      ? toast("Check your email for auth code...")
      : toast("Error getting your auth code.");
  };

  if (loading) return <LoadingIndicator />;

  return (
    <div className="min-h-screen bg-base-200 flex items-center justify-center">
      <div className="card w-96 bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title">Delete Account</h2>
          <form onSubmit={handleDeleteAccount}>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Email Address</span>
              </label>
              <input
                type="email"
                placeholder="Enter your email"
                className="input input-bordered"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>
            <div className="form-control mt-4">
              <label className="label">
                <span className="label-text">Authentication Code</span>
              </label>
              <input
                type="text"
                placeholder="Enter auth code"
                className="input input-bordered"
                value={authCode}
                onChange={handleAuthCodeChange}
                required
              />
            </div>
            <div className="form-control mt-6">
              <button type="submit" className="btn btn-primary">
                Delete Account
              </button>
            </div>
          </form>
          <div className="divider">OR</div>
          <div className="form-control mt-6">
            <button
              className="btn btn-secondary"
              onClick={handleRequestAuthCode}
            >
              Request Auth Code
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountPage;
