import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto p-6">
      <div className="card bg-base-100 shadow-xl p-6">
        <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
        <p className="text-gray-600 mb-4">
          Welcome to Amazing! Your privacy is important to us. This policy
          outlines how we collect, use, and protect your personal data.
        </p>

        <h2 className="text-xl font-semibold mt-4">
          1. Information We Collect
        </h2>
        <p className="text-gray-600">
          - Personal information: Name, email, phone number.
          <br />- Usage data: Interactions within the app.
        </p>

        <h2 className="text-xl font-semibold mt-4">
          2. How We Use Your Information
        </h2>
        <p className="text-gray-600">
          - To provide and improve our services.
          <br />- To send notifications and updates.
          <br />- To enhance user experience and security.
        </p>

        <h2 className="text-xl font-semibold mt-4">3. Data Protection</h2>
        <p className="text-gray-600">
          We implement industry-standard security measures to protect your
          information from unauthorized access.
        </p>

        <h2 className="text-xl font-semibold mt-4">4. Third-Party Services</h2>
        <p className="text-gray-600">
          We may use third-party services (e.g., payment gateways) that have
          their own privacy policies.
        </p>

        <h2 className="text-xl font-semibold mt-4">5. Your Rights</h2>
        <p className="text-gray-600">
          - You can request access, correction, or deletion of your personal
          data.
          <br />- You may opt out of certain data collection practices.
        </p>

        <h2 className="text-xl font-semibold mt-4">6. Contact Us</h2>
        <p className="text-gray-600">
          If you have any questions, contact us at:
          <br /> <strong>Email:</strong> campuusmart@gmail.com
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
