import { ReportFormData } from "../pages/ReportPage";
import apiClient, { getFailedResponse, processResponse } from "./client";

const endpoint = "/reports";

const create = async (report: ReportFormData) => {
  try {
    return processResponse(await apiClient.post(endpoint, report));
  } catch (error) {
    return getFailedResponse(error);
  }
};

export default { create };
