import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import {
  AdminPage,
  AuthPage,
  ChatsPage,
  ContactUsPage,
  DeleteAccountPage,
  EventsPage,
  HomePage,
  MartGuidePage,
  NewProductPage,
  NotFoundPage,
  NotificationsPage,
  OrdersPage,
  PrivacyPolicyPage,
  ProductActivityPage,
  ProductDetailsPage,
  ProfileOrderPage,
  ProfileOrdersPage,
  ProfilePage,
  ReportPage,
  ServicesPage,
  ShopEditPage,
  ShopOrderPage,
  ShopOrdersPage,
  ShopPage,
  ShoppingCartPage,
  ShopsPage,
} from "../pages";
import {
  ImagesContext,
  OrderContext,
  PostersContext,
  ProductContext,
  ProfileUserContext,
  ShopsContext,
  UsersContext,
} from "../contexts";
import { Order } from "../hooks/useOrder";
import { Poster } from "../hooks/usePosters";
import { Product } from "../hooks/useProducts";
import { Shop } from "../hooks/useShop";
import { User } from "../hooks/useUser";
import RedirectRoot from "../navigation/RedirectRoot";
import shopService from "../services/shops";
import usersService from "../services/users";

const AppRoutes = () => {
  const [images, setImages] = useState<File[]>([]);
  const [order, setOrder] = useState<Order>();
  const [posters, setPosters] = useState<Poster[]>([]);
  const [profileUser, setProfileUser] = useState<User>();
  const [shops, setShops] = useState<Shop[]>([]);
  const [shopsLoading, setShopsLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [product, setProduct] = useState<Product>();

  useEffect(() => {
    async function initShops() {
      setShopsLoading(true);
      const res = await shopService.getShops();
      setShopsLoading(false);

      if (res.ok) setShops(res.data as Shop[]);
    }

    async function getAllUsers(): Promise<User[]> {
      const res = await usersService.getUsers();

      return res.ok ? (res.data as User[]) : [];
    }

    async function initUsers() {
      setUsers(await getAllUsers());
    }

    initUsers();
    initShops();
  }, []);

  return (
    <ShopsContext.Provider value={{ shops, setShops, isLoading: shopsLoading }}>
      <ImagesContext.Provider value={{ images, setImages }}>
        <UsersContext.Provider value={{ users, setUsers }}>
          <ProfileUserContext.Provider value={{ profileUser, setProfileUser }}>
            <OrderContext.Provider value={{ order, setOrder }}>
              <PostersContext.Provider value={{ posters, setPosters }}>
                <ProductContext.Provider value={{ product, setProduct }}>
                  <Routes>
                    <Route path="/admin" element={<AdminPage />} />
                    <Route path="/auth" element={<AuthPage />} />
                    <Route path="/chats" element={<ChatsPage />} />
                    <Route path="/events" element={<EventsPage />} />
                    <Route path="/services" element={<ServicesPage />} />
                    <Route
                      path="/notifications"
                      element={<NotificationsPage />}
                    />
                    <Route path="/policy" element={<PrivacyPolicyPage />} />
                    <Route path="/report" element={<ReportPage />} />
                    <Route
                      path="/users/delete"
                      element={<DeleteAccountPage />}
                    />

                    <Route path="/mart/guides" element={<MartGuidePage />} />
                    <Route
                      path="/mart/guides/contact"
                      element={<ContactUsPage />}
                    />
                    <Route
                      path="/mart/guides/:guide"
                      element={<MartGuidePage />}
                    />
                    <Route path="/mart/cart" element={<ShoppingCartPage />} />
                    <Route path="/mart/orders" element={<OrdersPage />} />
                    <Route
                      path="/mart/profile/:userId"
                      element={<ProfilePage />}
                    />
                    <Route
                      path="/mart/profile/:userId/orders"
                      element={<ProfileOrdersPage />}
                    />
                    <Route
                      path="/mart/profile/:userId/orders/:orderId"
                      element={<ProfileOrderPage />}
                    />
                    <Route
                      path="/mart/products/:productId/activity"
                      element={<ProductActivityPage />}
                    />
                    <Route
                      path="/mart/products/:productId"
                      element={<ProductDetailsPage />}
                    />
                    <Route
                      path="/mart/products/new"
                      element={<NewProductPage />}
                    />
                    <Route
                      path="/mart/shops/:shopName"
                      element={<ShopPage />}
                    />
                    <Route
                      path="/mart/shops/:shopName/orders"
                      element={<ShopOrdersPage />}
                    />
                    <Route
                      path="/mart/shops/:shopName/orders/:orderId"
                      element={<ShopOrderPage />}
                    />
                    <Route path="/mart/shops/new" element={<ShopEditPage />} />
                    <Route path="/mart/shops" element={<ShopsPage />} />
                    <Route path="/mart" element={<HomePage />} />
                    <Route index element={<RedirectRoot />} />
                    <Route path="*" element={<NotFoundPage />} />
                  </Routes>
                </ProductContext.Provider>
              </PostersContext.Provider>
            </OrderContext.Provider>
          </ProfileUserContext.Provider>
        </UsersContext.Provider>
      </ImagesContext.Provider>
    </ShopsContext.Provider>
  );
};

export default AppRoutes;
