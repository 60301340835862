import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithRedirect,
  signOut as googleSignOut,
  User,
} from "firebase/auth";
import { jwtDecode } from "jwt-decode";

import { accountsKey } from "./accounts";
import { User as AppUser } from "../hooks/useUser";
import { whatsAppCacheKey } from "../components/WhatsAppNumberInput";
import cache from "./cache";
import apiClient, { getFailedResponse, processResponse } from "./client";

const tokenKey = "amazing-shop/token";
const endpoint = "/auth";

export interface GoogleUser extends User {}

const firebaseConfig = {
  apiKey: "AIzaSyCAtitgurCoK8LIYRWfo2i95Q6otoTmXSA",
  authDomain: "kisii-campus-mart-site.firebaseapp.com",
  projectId: "kisii-campus-mart-site",
  storageBucket: "kisii-campus-mart-site.appspot.com",
  messagingSenderId: "66759292374",
  appId: "1:66759292374:web:2a09e7ad0919c6a056e077",
  measurementId: "G-C2MJ2XQDCQ",
};

export const app = initializeApp(firebaseConfig);
export const googleAuth = getAuth(app);

const decodeJwt = (jwt: string) => jwtDecode(jwt);

const getJwt = () => localStorage.getItem(tokenKey);

const getCurrentUserFromCache = () => {
  try {
    const jwt = getJwt();
    if (jwt) {
      const user: AppUser | null = jwtDecode(jwt);
      return user;
    }
  } catch (error) {
    return null;
  }
};

const logout = () => {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(accountsKey);
  cache.remove(whatsAppCacheKey);
};

const loginWithJwt = (jwt: string) => localStorage.setItem(tokenKey, jwt);

export const signInWithGoogle = () =>
  signInWithRedirect(googleAuth, new GoogleAuthProvider());

export const signOut = async () => await googleSignOut(googleAuth);

const getAuthCode = async (email: string) => {
  try {
    return processResponse(await apiClient.post(`${endpoint}/code`, { email }));
  } catch (error) {
    return getFailedResponse(error);
  }
};

const loginWithCode = async (email: string, authCode: number | string) => {
  try {
    return processResponse(await apiClient.post(endpoint, { email, authCode }));
  } catch (error) {
    return getFailedResponse(error);
  }
};

export default {
  app,
  decodeJwt,
  getAuthCode,
  loginWithCode,
  getCurrentUserFromStorage: getCurrentUserFromCache,
  getJwt,
  loginWithJwt,
  logout,
  signInWithGoogle,
  signOut,
};
