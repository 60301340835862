import React, { useState } from "react";
import { toast } from "react-toastify";

import service from "../services/reports";

export interface ReportFormData {
  name: string;
  email: string;
  issueType: "Bug" | "Payment Issue" | "Account Issue" | "Other";
  description: string;
}

const ReportPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<ReportFormData>({
    name: "",
    email: "",
    issueType: "Bug",
    description: "",
  });

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setLoading(true);
    toast.loading("sending");
    const res = await service.create(formData);
    if (res.ok) toast.info("Report successful");
    toast.dismiss();
    setLoading(false);

    setFormData({ name: "", email: "", issueType: "Bug", description: "" });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-base-200 p-6">
      <div className="card w-full max-w-lg bg-white shadow-xl p-6">
        <h2 className="text-2xl font-bold text-center">Report an Issue</h2>
        <p className="text-center text-gray-600">Let us know what went wrong</p>
        <form className="mt-4" onSubmit={handleSubmit}>
          <div className="form-control">
            <label className="label">Your Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="input input-bordered w-full"
              placeholder="Enter your name"
              required
            />
          </div>

          <div className="form-control">
            <label className="label">Email Address</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="input input-bordered w-full"
              placeholder="Enter your email"
              required
            />
          </div>

          <div className="form-control">
            <label className="label">Issue Type</label>
            <select
              name="issueType"
              value={formData.issueType}
              onChange={handleChange}
              className="select select-bordered w-full"
            >
              <option value="Bug">Bug</option>
              <option value="Payment Issue">Payment Issue</option>
              <option value="Account Issue">Account Issue</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div className="form-control">
            <label className="label">Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="textarea textarea-bordered w-full"
              placeholder="Describe the issue"
              required
            ></textarea>
          </div>

          <div className="form-control mt-4">
            <button type="submit" className="btn btn-primary w-full">
              {loading ? "Submitting, wait..." : "Submit Report"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReportPage;
